<template>
  <div class="content-wrapper">
    <div class="modal-spinner" v-if="!loadingShow">
      <b-spinner />
    </div>
    <b-overlay variant="white" v-if="loadingShow" opacity="1">
      <section>
        <page-header
          screenName="Limpeza cache de regras"
          :linkItems="linkItems"
        />
      </section>
      <div class="p-2 card">
        <b-row>
          <b-col class="px-2" md="12" lg="12">
            <h2>Limpeza cache de regras</h2>
            <p class="m-0" v-text="message"></p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BSpinner, BRow, BCol } from "bootstrap-vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
export default {
  title: "Limpeza cache de regras",
  components: {
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    PageHeader,
  },
  data() {
    return {
      loadingShow: false,
      message: "",
      linkItems: [
        {
          name: "Suporte",
          routeName: "",
        },
        {
          name: "Limpeza cache de regras",
          active: true,
        },
      ],
    };
  },
  created() {
    this.realizaLimpezaCacheRegras();
  },
  methods: {
    realizaLimpezaCacheRegras() {
      this.loadingShow = false;
      try {
        this.$http.post(this.$api.limpezaCacheRegras()).then(({ data }) => {
          this.message = data.mensagem;
          this.loadingShow = true;
        });
      } catch (error) {
        this.loadingShow = true;
      }
    },
  },
};
</script>

<style scoped>
.modal-spinner {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>